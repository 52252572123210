<template>
    <div class="page">
        <div class="conter w-1350">
            <div class="conter-left">
                <div class="hzycenter-text">
                    <p></p>
                    <span>合作伙伴</span>
                </div> 
                <div class="nav-list">
                    <div class="nav-item" v-for="(item,index) in fenleiList" :key="index" @click="tabClick(item.id)">
                        <div :class="{active:id==item.id}">{{item.name}}</div>
                        <p><i class="el-icon-arrow-right"></i></p>                         
                    </div>
                </div>
            </div>

            <div class="conter-right">
                <div class="hzynav">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{path: '/partner'}">合作伙伴</el-breadcrumb-item>
                        <el-breadcrumb-item v-if="title!=''" :to="{path: `/partner_list?num=${num}`}">{{title}}</el-breadcrumb-item>
                        <el-breadcrumb-item v-if="name!=''">{{name}}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="details-wrap">
                    <div class="fuwu-list">
                        <div class="fuwu-item" v-for="(item,index) in liebiaoList" :key="index">
                            <div class="fuwu-item-text">{{item.name}}</div>
                            <div class="fuwu-item-p" v-html="delLabel(item.content)">进口需求子分类介绍进口需求子分类介绍进口需求子分类介绍</div>
                            <div class="fuwu-item-bt" @click="goTo('shangwu_details',{id:item.id,key:0})">服务内容介绍</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
    </div>
</template>

<script>
    import {getcountrys,getcitys, getplates,getplatexts } from "../../api/index"
    export default {
        data() {
            return {
                id:'',
                num:'',
                p_id:'',
                fenleiList:[],
                liebiaoList:[],
                plate_id:'',
                coun_id:'',
                city_id:'',
                title:'',
                name:''
            }
        },
        created() {
            this.id=this.$route.query.id 
            this.num=this.$route.query.num
            this.p_id=this.$route.query.p_id
    
            if (this.$route.query.num==2) {
                this.title=''
                this.plate_id=this.$route.query.id
                this.getplates()
            }else if (this.$route.query.num==1) {
                this.title="海外合作中心"
                this.coun_id=this.$route.query.id
                this.plate_id=26
                this.getcountrys()
            }else if(this.$route.query.num==0){
                this.title='国内合作中心'
                this.city_id=this.$route.query.id
                this.plate_id=26
                this.getcitys()              
            }

            if(this.$route.query.name){
                this.name=this.$route.query.name      
            }
            this.getplatexts()          
        },
        methods: {    
            // 去除富文本所有标签
            delLabel(input){
                if (input!=null) {
                    return input.replace(/<\/?[^>]*>/g, ""); 
                }
            },
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            tabClick(id){
                this.id=id;
                if (this.$route.query.num==2) {
                    this.plate_id=id
                }else if (this.$route.query.num==1) {
                    this.coun_id=id
                }else if(this.$route.query.num==0){
                    this.city_id=id   
                }
                this.getplatexts();
            },
            // 获取左侧分类
            getplates(){
                getplates({
                    p_id:26
                }).then((res)=>{
                    console.log(res)
                    if (res.code==200) {
                        this.fenleiList=res.data
                    }
                })
            },
            // 获取左侧国家分类
            getcountrys(){
                getcountrys({
                    p_id:this.p_id
                }).then((res)=>{
                    console.log(res);
                    if (res.code==200) {
                        this.fenleiList=res.data;
                    }
                })
            },
            // 获取左侧城市分类
            getcitys(){
                getcitys({
                    p_id:this.p_id
                }).then((res)=>{
                    console.log(res);
                    if (res.code==200) {
                        this.fenleiList=res.data;
                    }
                })
            },
            //获取列表
            getplatexts(){
                getplatexts({
                    plate_id:this.plate_id,
                    coun_id:this.coun_id,
                    city_id:this.city_id
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.liebiaoList=res.data.data
                    }
                })
            } 
        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;min-height: calc(100vh - 425px);}
    .hzynav{height:25px;display: flex;align-items: center;}
    .hzycenter-text{
        height:25px;display: flex;align-items: center;
        p{width: 3px;height: 25px;background: #0071DB;}
        span{font-size: 18px;color: #0071DB;margin-left: 10px;}
    }
    .conter{
        margin: auto;margin-bottom:140px;margin-top: 45px;display: flex;
        .conter-left{
            width:230px;flex-shrink: 0;margin-right: 15px;
            .nav-list{
                border: 1px solid #CDCDCD;margin-top:15px;
                max-height: 70vh;
                overflow: auto;
                &::-webkit-scrollbar {
                width:5px;
                }
                /* 滚动槽 */
                &::-webkit-scrollbar-track {
                -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
                border-radius:10px;
                }
                /* 滚动条滑块 */
                &::-webkit-scrollbar-thumb {
                border-radius:10px;
                background:rgba(0,0,0,0.1);
                -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
                }
                .nav-item{cursor: pointer;
                    height: 50px;padding: 0 20px;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #CDCDCD;
                    div{font-size: 16px;color: #3D3D3D;}
                    p{color: #0071DB;font-size: 22px;font-weight: bold;}
                    &:hover{
                        div{color: #0071DB;}
                    }
                    &:last-of-type{border-bottom: none;}
                }
                .active{color: #0071DB !important;}

            }
        }
        .conter-right{
            flex: 1;
            .details-wrap{
                background: #fff;margin-top: 15px;padding: 30px 20px;
                .fuwu-list{
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 70px;
                    .fuwu-item{
                        width: 18.5%;
                        margin-right: 1.875%;
                        margin-bottom: 30px;
                        border: 1px solid #E8E8E8;
                        box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.16);
                        padding:  0 19px;
                        box-sizing: border-box;
                        text-align: center;
                        &:nth-child(5n+0){
                            margin-right: 0;
                        }
                        .fuwu-item-text{
                            color: #000;
                            font-size: 16px;
                            font-weight: bold;
                            margin-top: 25px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                        .fuwu-item-p{
                            height: 40px;
                            color: #666;
                            font-size: 14px;
                            line-height: 20px;
                            margin: 15px 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                        .fuwu-item-bt{
                            width: 80%;
                            height: 33px;
                            border-radius: 15px;
                            background: linear-gradient(138deg, #CF694E, #FD9976);
                            margin: auto;
                            margin-bottom: 13px;
                            text-align: center;
                            line-height: 33px;
                            color: #fff;
                            font-size: 14px;
                            cursor: pointer;
                        }

                    }
                }
            }
        }
        
    }

</style>